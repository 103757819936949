<script>
  import {
    mdiAlertOutline,
    mdiFilterOutline,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiPrinterOutline,
    mdiRefresh,
    mdiUndoVariant
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  import Vue from "vue";

  import AppLoading from "@/components/base/loading/AppLoading.vue";
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import IdrFilterMenu from "@/components/common/filter-menu/DailyLogFilterMenu.vue";
  import { useLogger } from "@/composables/useLogger";
  import { resolveOrganizationLogo } from "@/composables/useOrganizations";
  import { usePagination } from "@/composables/usePagination";
  import useVuetify from "@/helpers/vuetify";
  import { store } from "@/store";
  import themeConfig from "@/themeConfig";
  import PrintDailyLogReport from "@/views/apps/daily-log/PrintDailyLogReport.vue";

  import { useDailyLogInvoice } from "./useDailyLogInvoice";
  import {
    useDailyLogViewList,
    useDailyLogViewListBreadcrumbs,
    useDailyLogViewListDatatable
  } from "./useDailyLogList";

  export default defineComponent({
    name: "DailyLogList",

    components: {
      Breadcrumbs,
      IdrFilterMenu,
      AppLoading,
      PrintDailyLogReport
    },

    setup() {
      const logger = useLogger();
      const { rootThemeClasses } = useVuetify();
      const {
        dailyLogViews,
        dailyLogViewsLoaded,
        dailyLogViewsPending,
        dailyLogViewsLatestQuery,
        findDailyLogViews,
        dailyLogViewsParams
      } = useDailyLogViewList();
      const { breadcrumbs } = useDailyLogViewListBreadcrumbs();
      const { options, itemsPerPageOptions } = usePagination({
        initialItemsPerPage: 25,
        initialSortBy: ["idr_date"],
        itemsPerPageOptions: [5, 10, 25, 50],
        storePath: "daily-logs-view"
      });
      const { headers } = useDailyLogViewListDatatable();
      const { DailyLogInvoice } = Vue.$FeathersVuex.api;
      const { processFormData, undoMarkAsInvoiced } = useDailyLogInvoice();

      // Other state
      const selectedItems = ref([]);
      const printItem = ref(null);
      const singleSelect = ref(false);

      // Methods
      const handleMarkAsInvoiced = async () => {
        logger.debug(
          "Attempting to mark items as invoiced:",
          selectedItems.value
        );
        try {
          store.dispatch("daily-log-invoices/updateLoading", true);
          await processFormData(selectedItems.value);
          logger.info(
            `Successfully marked ${selectedItems.value.length} items as invoiced`
          );
          snackbar({
            text: `Items successfully marked as 'Invoiced'.`,
            color: "success"
          });
        } catch (error) {
          logger.error("Failed to mark items as invoiced:", error);
          snackbar({
            text: `Unable to mark items as "Invoiced". Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          selectedItems.value = [];
          store.dispatch("daily-log-invoices/updateLoading", false);
        }
      };

      const handleUnmarkAsInvoiced = async (item) => {
        logger.debug("Attempting to unmark item as invoiced:", item);
        try {
          store.dispatch("daily-log-invoices/updateLoading", true);
          await undoMarkAsInvoiced(item);
          logger.info(
            `Successfully unmarked item ${item.fulcrum_id} as invoiced`
          );
          snackbar({
            text: `Item successfully unmarked as 'Invoiced'.`,
            color: "success"
          });
        } catch (error) {
          logger.error("Failed to unmark item as invoiced:", error);
          snackbar({
            text: `Unable to mark item as "Not Invoiced". Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          selectedItems.value = [];
          store.dispatch("daily-log-invoices/updateLoading", false);
        }
      };

      const handlePrint = (item) => {
        logger.debug("Initiating print for item:", item);
        printItem.value = item;
        setTimeout(() => {
          logger.debug("Executing print command");
          window.print();
        }, 500);
      };

      const invoiceStatusColor = (invoiced) => {
        if (invoiced === true) return "success";
        if (invoiced === false) return "default";
      };

      const refreshDataTable = async () => {
        logger.debug(
          "Daily Log Invoice Table refreshed with params:",
          dailyLogViewsParams.value
        );
        try {
          await findDailyLogViews(dailyLogViewsParams);
          logger.info("Daily Log Views successfully reloaded");
          snackbar({
            text: `Daily Log Views successfully reloaded. Enabled filters have been preserved.`,
            color: "info"
          });
        } catch (error) {
          logger.error("Failed to refresh Daily Log Views:", error);
          snackbar({
            text: `Failed to refresh Daily Log Views. Please try again.`,
            color: "error"
          });
        }
      };

      //Event listeners
      DailyLogInvoice.on("patched", async () => {
        logger.debug("Daily Log Invoice patched event received");
        await findDailyLogViews(dailyLogViewsParams);
      });

      // Log initial setup completion
      logger.debug("DailyLogList component setup completed");

      return {
        // UI State
        rootThemeClasses,
        dailyLogViewsLoaded,
        dailyLogViewsPending,
        dailyLogViewsLatestQuery,
        appLogo: themeConfig.app.logoDark,

        // Breadcrumbs
        breadcrumbs,

        // Table state
        headers,
        selectedItems,
        singleSelect,
        dailyLogViews,
        options,
        itemsPerPageOptions,

        // Methods
        refreshDataTable,
        handleMarkAsInvoiced,
        handleUnmarkAsInvoiced,
        handlePrint,
        invoiceStatusColor,
        resolveOrganizationLogo,

        // Print state
        printItem,

        // Icons
        icons: {
          mdiAlertOutline,
          mdiFilterOutline,
          mdiMagnify,
          mdiMapMarkerOutline,
          mdiPrinterOutline,
          mdiRefresh,
          mdiUndoVariant
        }
      };
    }
  });
</script>

<template>
  <div v-if="dailyLogViewsLoaded">
    <breadcrumbs class="mb-5" :items="breadcrumbs" />
    <v-card id="invoice-list" class="no-print" flat>
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex title">
          IDRs
          <span class="ml-1" v-if="selectedItems.length > 0">
            ({{ selectedItems.length }})
          </span>
        </div>
        <div class="d-flex">
          <v-btn
            small
            color="primary"
            class="me-3"
            :disabled="selectedItems.length === 0"
            @click="handleMarkAsInvoiced"
          >
            <span v-if="selectedItems.length === 1">
              Mark {{ selectedItems.length }} IDR as Invoiced
            </span>
            <span v-else-if="selectedItems.length > 1">
              Mark {{ selectedItems.length }} IDRs as Invoiced
            </span>
            <span v-else>Mark as Invoiced</span>
          </v-btn>

          <idr-filter-menu></idr-filter-menu>

          <v-btn
            small
            outlined
            color="secondary"
            class="ml-3"
            @click="refreshDataTable"
          >
            <v-icon>{{ icons.mdiRefresh }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dailyLogViewsLatestQuery.response.data"
        :options.sync="options"
        :server-items-length="dailyLogViewsLatestQuery.response.total"
        :loading="dailyLogViewsPending"
        show-select
        :single-select="singleSelect"
        item-key="fulcrum_id"
        v-model="selectedItems"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <template #[`item.idr_date`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="48"
            >
              <v-img
                v-if="item.contractor_name"
                :src="resolveOrganizationLogo(item.contractor_id)"
              ></v-img>
              <span v-else>
                {{ item.contractor_name.slice(0, 2).toUpperCase() }}
              </span>
            </v-avatar>
            <div v-if="item.fulcrum_id" class="d-flex flex-column ms-3">
              <div class="d-flex flex-row align-center">
                <router-link
                  :to="{
                    name: 'apps-daily-log-view',
                    params: {
                      project: item.project_id,
                      record: item.const_id,
                      idr: item.fulcrum_id
                    }
                  }"
                  class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.idr_date }}
                </router-link>
                <v-tooltip class="d-flex ml-1" right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      v-if="item.delay_reason !== null"
                      v-on="on"
                      small
                    >
                      {{ icons.mdiAlertOutline }}
                    </v-icon>
                  </template>
                  <span>{{ item.delay_reason }} delay</span>
                </v-tooltip>
              </div>
              <small class="secondary--text">
                <span class="font-weight-semibold">
                  {{ item.permit_num }} -
                </span>
                {{ item.location }}
              </small>
            </div>
          </div>
        </template>

        <template #[`item.invoiced`]="{ item }">
          <v-chip
            small
            :color="invoiceStatusColor(item.invoiced)"
            :class="`${invoiceStatusColor(item.invoiced)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.invoiced ? "Invoiced" : "Not Invoiced" }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-space-between">
            <v-btn class="d-flex" icon x-small>
              <v-icon color="primary" @click="handlePrint(item)">
                {{ icons.mdiPrinterOutline }}
              </v-icon>
            </v-btn>
            <v-tooltip v-if="item.invoiced === true" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="d-flex" icon x-small v-bind="attrs" v-on="on">
                  <v-icon color="primary" @click="handleUnmarkAsInvoiced(item)">
                    {{ icons.mdiUndoVariant }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Undo Mark as Invoiced</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <print-daily-log-report class="idr-report" :item="printItem" />
  </div>
  <app-loading v-else></app-loading>
</template>

<style lang="scss">
  .idr-report {
    display: none;
  }

  @media print {
    .app-navigation-menu,
    .v-app-bar,
    .v-footer {
      display: none !important;
    }

    .v-main,
    .app-content-container {
      padding: 0 !important;
    }

    .no-print {
      display: none !important;
    }

    .idr-report {
      display: block;
    }
  }
</style>
