import Vue from "vue";

import { store } from "@/store";
const { DailyLogInvoice } = Vue.$FeathersVuex.api;
import { useLogger } from "@/composables/useLogger";

export function useDailyLogInvoice() {
  const logger = useLogger();
  const processFormData = async (itemToEdit) => {
    logger.debug("itemToEdit: ", itemToEdit);
    // Remove the try/catch here since we're handling it in the caller
    return Promise.all(
      itemToEdit.map(async (item) => {
        let dailyLogInvoice = new DailyLogInvoice({
          fulcrum_id: item.fulcrum_id
        }).clone();

        dailyLogInvoice.invoiced_by = store.state["auth"].user.id;
        dailyLogInvoice.invoiced_on = new Date();

        await dailyLogInvoice.patch();
        await dailyLogInvoice.commit();
        logger.debug("dailyLogInvoice: ", dailyLogInvoice);
      })
    );
  };

  const undoMarkAsInvoiced = async (itemToEdit) => {
    let dailyLogInvoice = new DailyLogInvoice({
      fulcrum_id: itemToEdit.fulcrum_id,
      invoiced_by: null,
      invoiced_on: null
    }).clone();

    await dailyLogInvoice.patch();
    await dailyLogInvoice.commit();
    logger.debug("dailyLogInvoice unmarked: ", dailyLogInvoice);
  };
  return {
    processFormData,
    undoMarkAsInvoiced
  };
}
