import { getCurrentInstance } from "@vue/composition-api";
import Vue from "vue";

export function useLogger() {
  // Get the current component instance
  const vm = getCurrentInstance();

  // If we're in a component context, use the component's logger
  // Otherwise fall back to Vue's global logger
  const logger = vm ? vm.proxy.$log : Vue.$log;

  return {
    debug: (...args) => logger.debug(...args),
    info: (...args) => logger.info(...args),
    warn: (...args) => logger.warn(...args),
    error: (...args) => logger.error(...args)
  };
}
